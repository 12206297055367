import { ContentLayout } from 'components/Layout';

import { Button, FullScreenSpinner } from 'components/Elements';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useContract } from '../api/getContract';
import { UPLOADS_URL } from 'config';
import { convertToWords, toWords } from 'utils/toWords';
import { formatDate } from 'utils/format';
import { formatText } from 'utils/formatText';
import { useRef } from 'react';
import { useAuth } from 'lib/auth';
import { useReactToPrint } from 'react-to-print';
import '../contracts.css';
import { SubmitBorrowerSignature } from '../components/SubmitBorrowerSignature';
import { formatDateToISO8601 } from 'utils/formatDateToISO8601';
import { UpdateAddress } from '../components/UpdateAddress';
import { numToWords } from 'utils/numToWords';
import { ContractCollateral } from '../components/ContractCollateral';
import { SubmitLenderSignature } from '../components/SubmitLenderSignature';
import { Download } from 'react-feather';
import Swal from 'sweetalert2';
import { useMyLoan } from 'features/myLoan/api/getMyLoan';
import { AuditLog } from './AuditLog';
import { ServiceFee } from '../components/ServiceFee';

export const Contract = () => {
  const { id } = useParams();

  const contractQuery = useContract({ id });

  const navigate = useNavigate();

  const { user } = useAuth();
  const contractDoc = useRef();
  const myLoanQuery = useMyLoan();

  const handlePrint = useReactToPrint({
    content: () => contractDoc.current,
    documentTitle: `EFL ${contractQuery.data?.product?.product_title?.toUpperCase()} AND REPAYMENT SCHEDULE_${contractQuery.data?.borrower?.basicInformation?.firstName?.toUpperCase()}_${contractQuery.data?.contract_date?.toLocaleString()}`,
    onBeforeGetContent: () => {
      if (contractQuery.data?.borrower_signature?.confirmed_by_borrower) {
        return true;
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Please sign the contract before printing',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        return false;
      }
    },
  });

  if (contractQuery.isLoading || myLoanQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  if (!contractQuery.data) return null;

  // const late_fee_percentage = getLateFeePercentage(
  //   contractQuery.data?.loanAmount,
  //   contractQuery.data?.product?.late_fees
  // );

  // const service_fee_percentage = getServiceFeePercentage(
  //   contractQuery.data?.loanAmount,
  //   contractQuery.data?.product?.service_fees
  // );

  const routes = [
    {
      name: 'Contracts',
      path: '/app/contracts',
    },
    {
      name: 'Contract',
      path: `/app/contracts/${id}`,
    },
  ];

  return (
    <ContentLayout
      title={'Contract'}
      end={
        <div className="flex flex-row items-center space-x-4">
          {myLoanQuery.data?.status !== 'NEW' &&
            myLoanQuery.data?.status !== 'DUE_DILIGENCE' &&
            myLoanQuery.data?.status !== 'EFL_APPROVED' &&
            myLoanQuery.data?.status !== 'OFFER_ACCEPTED' && (
              <button onClick={handlePrint}>
                <Download size={16} />
              </button>
            )}

          <Button size="sm" onClick={() => navigate('/app/contracts')}>
            Back
          </Button>
        </div>
      }
    >
      {/* <Breadcrumbs routes={routes} /> */}
      <div className=" mt-4">
        {!contractQuery.data?.borrower_signature?.confirmed_by_borrower &&
          user.role === 'BORROWER' && (
            <div className="bg-orange-100 text-sm border border-orange-500 p-4 rounded-xl shadow">
              <span className="font-bold text-lg">Important Notice!</span>
              <br />
              Please review your contract carefully and provide the following pending details;
              {!contractQuery.data?.borrower?.addressInformation?.permanentAddress && (
                <div className="">- Submit your location details</div>
              )}
              {!contractQuery.data?.borrower_signature?.confirmed_by_borrower && (
                <div className="">
                  - Sign Contract (
                  <button
                    onClick={() => {
                      const element = document.getElementById('borrower_signature');
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                      }
                    }}
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  >
                    At end of contract
                  </button>
                  )
                </div>
              )}
            </div>
          )}
        {!contractQuery.data?.lender_signature?.confirmed_by_lender && user.role === 'LENDER' && (
          <div className="bg-orange-100 text-sm border border-orange-500 p-4 rounded-xl shadow">
            <span className="font-bold text-lg">Important Notice!</span>
            <br />
            Please review your contract carefully and provide the following pending details;
            {!contractQuery.data?.lender_signature?.confirmed_by_lender && (
              <div className="">
                - Sign Contract (
                <button
                  onClick={() => {
                    const element = document.getElementById('lender_signature');
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                >
                  At end of contract
                </button>
                )
              </div>
            )}
          </div>
        )}
      </div>

      {/* Contract */}
      <div className="mt-4 mb-4">
        <div ref={contractDoc} className="px-4 md:px-32 relative contract  bg-white p-8 ">
          <div className="absolute inset-0 flex justify-center mt-72 pointer-events-none">
            <div
              className="text-lightblue-300 font-black opacity-10"
              style={{
                fontSize: '24rem',
              }}
            >
              EFL
            </div>
          </div>
          <div className="p-4 relative z-10">
            <div className="mx-auto  mb-4 text-sm md:text-md">
              <div className="font-black mb-4 text-center">THE REPUBLIC OF UGANDA</div>
              <div className="font-bold mb-4  text-center">
                IN THE MATTER OF THE CONTRACTS ACT NO. 7 OF 2010
              </div>
              <div className="font-bold mb-4 text-center">AND</div>
              <div className="font-bold mb-4 text-center">
                IN THE MATTER OF THE TIER 4 MICROFINANCE INSTITUTIONS AND
              </div>
              <div className="font-bold mb-8 text-center">MONEYLENDERS ACT NO. 13 OF 2016</div>
              <div className="font-bold text-center uppercase underline mb-3">
                {contractQuery.data?.product?.product_title} agreement
              </div>
              <div className="text-center mt-4 mb-6">
                <span className="font-bold text-center">THIS AGREEMENT </span> is made this day of{' '}
                <span
                  className={`font-bold ${
                    formatDate(contractQuery.data?.contract_date) === 'Pending' && `text-red-500`
                  }`}
                >
                  {formatDateToISO8601(contractQuery.data?.contract_date)}
                </span>
              </div>
              <div className="font-bold text-center mb-6">BETWEEN</div>
              <div className="mb-6">
                <span className="font-bold">
                  ENABLING FINANCE LTD operating through her ‘Finablr’ app
                </span>
                , of <span className="font-bold">P.O. Box 12284, Kampala Tel: 0706400057 </span>
                (hereinafter referred to as
                <span className="font-bold"> “the Lender"</span> which expression shall where the
                context so admits includes its successors in title, legal representatives and
                assigns) of the one part;
              </div>
              <div className="mb-6 font-bold text-center">AND</div>
              <div className="">
                <div className="font-bold">
                  <div>{contractQuery.data?.borrower?.basicInformation?.firstName} of </div>
                  <div className="">
                    {contractQuery.data?.borrower?.addressInformation?.permanentAddress ? (
                      <>{contractQuery.data?.borrower?.addressInformation?.permanentAddress}</>
                    ) : (
                      <>
                        <UpdateAddress />
                      </>
                    )}
                  </div>
                </div>
                , (hereinafter referred to as “<span className="font-bold">the Borrower</span>”,
                (which expression shall where the context so admits include his/her successors in
                title, legal representatives and assigns) of the other part.
              </div>
            </div>
            <div className="mb-4 flex flex-col items-start space-y-6">
              <div className="font-bold">NOW THEREFORE THE PARTIES HERETO AGREE AS FOLLOWS: -</div>
              <div className="">
                1. Upon the Borrower’s application using the EFL-Finablr App and acceptance of the
                loan offer, the Lender hereby agrees to advance to the Borrower a loan of{' '}
                <span className="font-bold">
                  Ug. Shs. {contractQuery.data?.loanAmount?.toLocaleString()} (
                  {toWords && toWords.convert(contractQuery.data?.loanAmount)} Only)
                </span>{' '}
                on such terms and conditions laid down in the Finablr platform and the Borrower
                hereby accepts responsibility for all obligations and debts under this agreement and
                acknowledges such indebtedness to the Lender.
              </div>
              <div className="">
                2. The loan shall be used for the purpose of{' '}
                <span className="font-bold uppercase">{contractQuery.data?.loan?.purpose}</span>;
                diversion of loan funds to other uses shall not be permitted and will be considered
                a breach of agreement on loan use. It may also cause EFL to restrict future loans to
                the Borrower or to recall the loan altogether. EFL has a continuing right to
                investigate whether the loan was used for the intended purpose.
              </div>
              <div className="">
                3. The loan shall be for a period of{' '}
                <span className="font-bold">3 (Three) months</span> with a grace period of{' '}
                <span className="font-bold">three (3) days</span> afterward.
              </div>
              <div className="">
                4. The loan shall attract interest at the rate of{' '}
                <span className="font-bold">
                  {contractQuery.data?.loan?.interestRate}% per month
                </span>{' '}
                for the{' '}
                <span className="font-bold">
                  {contractQuery.data?.loan?.term} (
                  {toWords && toWords.convert(contractQuery.data?.loan?.term)}) months{' '}
                </span>
                on{' '}
                <span className="font-bold uppercase">
                  {formatText(contractQuery.data?.loan?.paymentMethod)}
                </span>
                .
              </div>
              <div className="">
                5. Once overdue, the outstanding loan principal amount shall attract a late fee at
                the rate of <span className="font-bold">0.37% per day. (11% per month)</span> past
                the grace period.
              </div>
              <div className="">
                6. Application, establishment, administration and other fees shall be charged at a
                rate of{' '}
                <span className="font-bold">
                  <ServiceFee
                    amount={contractQuery.data?.loanAmount}
                    serviceFees={contractQuery.data?.product?.service_fees}
                  />
                </span>{' '}
                of the loan amount approved.
              </div>
              <div className="">
                7. Costs of stamps, fees, seals and other costs in connection with perfecting of
                collateral associated with the loan shall be borne by the Borrower.
              </div>
              <div className="">
                8. The Borrower shall pay all incidental fees from the loan at the time of
                disbursement.
              </div>
              <div className="">
                9. The Borrower agrees to repay principal and interest to EFL in{' '}
                <span className="font-bold">
                  {' '}
                  {contractQuery.data?.loan?.term} (
                  {contractQuery.data?.loan && numToWords(contractQuery.data?.loan?.term)}) monthly
                </span>{' '}
                installments as stipulated in the Finablr loan repayment schedule sent to the
                borrower via our official WhatsApp business line. The Finablr loan repayment
                schedule shall constitute an integral part of this agreement. The first installment
                of the loan shall be due on the{' '}
                <span className="font-bold">
                  {contractQuery.data?.loan &&
                    formatDateToISO8601(contractQuery.data?.loan?.payments[1]?.dateExpected)}
                </span>
                . In the event that the loan repayment date does not fall on a working day, the said
                loan repayment installment shall and is hereby agreed to be paid before the end of
                the next working day.
              </div>
              <div className="">
                10. Where the borrower defaults on any monthly installment as per the schedule for
                more than <span className="font-bold">7 (seven) days</span>, he/she shall attract a
                one-off <span className="font-bold">penalty of 11%</span> applied onto the principal
                installment amount in default for that respective month. This shall be applicable to
                all installments during the active loan period.
              </div>
              <div className="">
                11. The Borrower has the option to make an{' '}
                <span className="font-bold">early repayment</span> of the principal or entire loan
                on giving <span className="font-bold">three days (3) days-notice</span> to EFL,
                through its official channels.
              </div>
              <div className="">
                12. Where the Borrower is in breach of the loan agreement and/or is in default and
                the{' '}
                <span className="font-bold">
                  loan installment remains unpaid for 60 (Sixty) days, EFL will recall the loan and
                  immediately demand repayment of all the outstanding loan balance
                </span>
                . EFL will therefore proceed against the Borrower for recovery of the total
                principal plus interest and all incidental costs.
              </div>
              <div className="">
                13. EFL reserves the right to recall the loan at any time, whether there is default
                or not, as long as in EFL’s own assessment the Borrower has become a risky Borrower.
                Where such a loan is recalled, the entire outstanding loan balance shall fall due
                and owing immediately and the recovery procedure shall be put into effect.
              </div>
              <div className="">
                14. For as long as the loan falls in arrears, the Borrower shall incur charges on
                the principal in arrears at the rate(s) indicated in paragraphs five (5) above,
                until the entire sum is fully paid.
              </div>
              <div className="">
                15. All loan repayments shall be made by the Borrower{' '}
                <span className="font-bold">ONLY</span> on the EFL collection accounts described
                below and a receipt issued electronically to the Borrower by EFL. All E-payment
                methods may be made directly to the account described below.
                <br /> <br />
                <div class="overflow-x-auto">
                  <table class="table-auto border-collapse border border-gray-400 w-full">
                    <thead>
                      <tr>
                        <th class="px-4 py-2 bg-gray-200 border border-gray-400 text-start font-bold">
                          INSTITUTION:
                        </th>
                        <td class="px-4 py-2 border border-gray-400">
                          Stanbic Bank, Ntinda Branch
                        </td>
                        <td class="px-4 py-2 border border-gray-400"></td>
                        <td class="px-4 py-2 border border-gray-400">Airtel Money</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="px-4 py-2 font-bold text-start">ACCOUNT NAME:</td>
                        <td class="px-4 py-2 border border-gray-400">Enabling Finance Limited</td>
                        <td class="px-4 py-2 border border-gray-400 font-bold">OR</td>
                        <td class="px-4 py-2 border border-gray-400">Yyeyo Luke</td>
                      </tr>
                      <tr>
                        <td class="px-4 py-2 font-bold text-start">ACCOUNT NUMBER:</td>
                        <td class="px-4 py-2 border border-gray-400">9030021053324</td>
                        <td class="px-4 py-2 border border-gray-400"></td>
                        <td class="px-4 py-2 border border-gray-400">0706400057</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="">
                16. The borrower shall include in his repayment any withdraw fees applicable.
              </div>
              <div className="">
                17. The Borrower has pledged to the Lender collateral security to ensure that the
                loan is paid through foreclosure in the event of default including any amounts due
                from penalty, interest, service charges and/or other costs or/and breach of the loan
                agreement. The Borrower has, by way of security for repayment of the said loan sum,
                deposited into EFL’s custody collateral described below and the same shall be
                continuing security until the entire loan and any incidental costs have been fully
                repaid, or where the Borrower and the Lender have executed a subsequent addendum to
                swap the same;
              </div>
              {contractQuery.data?.loan?.collateral &&
                contractQuery.data?.loan?.collateral?.map((collateral, index) => (
                  <ContractCollateral collateral={collateral} index={index} />
                ))}

              <div className="">
                18. The borrower(s) guarantee that they are the registered owners of the above
                described land and developments thereon and/ or otherwise are vested with the legal
                authority to transact on the said property.
              </div>
              <div className="">
                19. The Borrower has the obligation to deliver to EFL all the original documents of
                collateral, and the same shall be in custody of EFL or its security trustees until
                the loan plus all incidental costs have been repaid. It is hereby agreed and
                understood by all parties that the Lender shall not be liable for collateral
                documents that may be held and delayed in third party government offices where EFL
                submitted them for lien registration such as in the event of mortgage registration
                or release or caveat registration or lifting.
              </div>
              <div className="">
                20. The borrower further consents to the lender disbursing agreed sums of money
                directly to service providers including Uganda Revenue Authority on their behalf
                with record being kept.
              </div>
              <div className="">
                21. The lender's interest shall not be affected by any third party interest and the
                borrower confirms that he will not subject the collateral to any third party
                interests for the period when the loan is still running.
              </div>
              <div className="">
                22. The borrower confirms that in case he subjects the collateral to any third party
                interest in anyway without the written consent of the lender, the lender shall
                terminate the contract with immediate effect.
              </div>
              <div className="">
                23. Upon such termination above, the borrower shall be at liberty to rescue the
                collateral by paying all the outstanding balance or the lender shall be at liberty
                to sell the collateral and realise her money without any court order.
              </div>
              <div className="">
                24. The borrower confirms that subjecting the collateral in this agreement to any
                third party interest, without written consent from the lender, shall mean forfeiting
                its/his interest in the property.
              </div>
              <div className="">
                25. In case of death, bankruptcy or liquidation of the Borrower, all sums which
                would at the time have been owing to the Lender by the Borrower shall be recovered
                from the Borrower’s estate and EFL shall have first preference in settling the debts
                of the deceased or the bankrupt Borrower. However, the Borrower’s estate may not be
                resorted to where the outstanding loan amount and interest plus any other incidental
                costs have been paid by foreclosure of the attached collateral of any insurance
                company.
              </div>
              <div className="">
                26. The Borrower hereby agrees and authorizes EFL to make inquiries from any bank,
                financial institution or association or any approved credit reference database to
                confirm any information provided by the Borrower and provide and share all statutory
                information in respect of the loan facility and all subsequent facilities as shall
                be advanced to the Borrower from time to time and in line with applicable Uganda
                Microfinance regulatory Authority Regulations.
              </div>
              <div className="">
                27. Regarding the agreement and all related matters, the general terms and
                conditions, which have already been agreed to by the Borrower on the Finablr
                platform and in this agreement also apply and bind the Borrower, even though not
                attached to this agreement.
              </div>
              <div className="">
                28. This agreement shall be governed by the laws of the Republic of Uganda.
              </div>
              <div className="">
                29. If any provision of this agreement or application thereof shall, for any reason
                and to any extent, be invalid or unenforceable, neither the remainder of this
                agreement nor the application of the provision to other persons, entities or
                circumstances shall be affected, but instead shall be enforced to the maximum extent
                permitted by law.
              </div>
              <div className="">
                30. The Borrower agrees to pay fees, charges and costs incidental to the recovery or
                collection of the outstanding unpaid principal and interest amount and EFL shall be
                at liberty to debit the Borrower’s account accordingly.
              </div>
              <div className="">
                31. It is hereby agreed by all parties that if any of the monies for the time being
                owing to the Lender are not forthwith paid on demand or having become payable
                without demand, the statutory powers of sale of deposited collateral without
                recourse to court conferred on collateral by the Tier 4 Microfinance Institutions
                And Moneylenders Act No. 13 Of 2016 including the powers to sell by private treaty
                without reference to Court shall immediately become exercisable. The procedure to be
                followed in such circumstance is prescribed in the terms and conditions of the
                Finablr platform available on{' '}
                <a
                  href="https://www.enablingfinancelimited.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 underline"
                >
                  www.enablingfinancelimited.com
                </a>
              </div>
              <div className="">
                32. No indulgence, waiver, election or non-election by either party under this
                agreement shall affect the other party’s duties and liabilities hereunder.
              </div>
              <div className="">
                33. The parties hereby agree that this document and its annexures contain the entire
                agreement between the parties and this agreement shall not be modified, changed,
                altered or amended in any way except through a written amendment signed by the
                Borrower and EFL.
              </div>
              <div className="">
                34. The parties shall in good faith make efforts to resolve any dispute, controversy
                or claim of any nature whatsoever arising out of or relating to or in connection
                with this contract. To this end, the parties shall each appoint representatives who
                shall meet and attempt to resolve any dispute arising between them. If any dispute
                arising from this contract cannot be settled within seven (7) working days, such
                dispute shall be referred to arbitration by a single arbitrator and finally settled
                in accordance with the provisions of the Arbitration and Conciliation Act Cap 4 Laws
                of Uganda.
              </div>
              <div className="">
                35. EFL shall have the responsibility of tracking all events regarding this
                transaction from point of application to point of full repayment inclusive of
                producing accountability to both parties for all minor and major transactions by way
                of source documents such as receipts, payment vouchers, demand notices all on behalf
                of the concerned parties.
              </div>
              <div className="">
                36. By executing this Agreement, the Borrower hereby acknowledges receipt of the
                loan amount.
              </div>

              <div className="">
                I, the above-named borrower hereby confirms that I have read, understood and agreed
                to the above conditions.
              </div>
              <div className="">
                <span className="font-bold">IN WITNESS WHEREOF</span> the parties have hereto set
                their respective hands and seals hereunto on the day, month and year first above
                written.
              </div>
            </div>
            <div className="mb-4">
              <div className="grid grid-cols-2 gap-4 mb-12 items-center">
                <div className="">
                  <div className="">
                    <span className="font-bold">SIGNED</span> by
                  </div>
                  <div className="">YYEYO LUKE</div>
                  <div className="">On behalf of LENDER</div>
                  <div className="">ENABLING FINANCE LIMITED</div>
                </div>
                <div className="">
                  <div className="">
                    <img
                      src={UPLOADS_URL + '/signatures/yyeyo_luke.png'}
                      className="h-16"
                      alt="Signature"
                    />
                  </div>
                  <div className="">LENDER</div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 mb-12 items-center">
                <div className="">
                  <div className="" id="borrower_signature">
                    SIGNED by
                  </div>
                  <div className="">{contractQuery.data?.borrower?.basicInformation?.username}</div>
                  <div className=""></div>
                  <div className=""></div>
                </div>
                <div className="">
                  <div className="">
                    {contractQuery.data?.borrower_signature?.confirmed_by_borrower && (
                      <img
                        src={
                          UPLOADS_URL +
                          '/signatures/' +
                          contractQuery.data?.borrower_signature?.location
                        }
                        className="h-16"
                        alt="Signature"
                      />
                    )}
                    {!contractQuery.data?.borrower_signature?.confirmed_by_borrower &&
                      user.role === 'BORROWER' && (
                        <SubmitBorrowerSignature
                          location={contractQuery.data?.borrower_signature?.location}
                          id={id}
                        />
                      )}
                  </div>
                  <div className="">THE BORROWER</div>
                </div>
              </div>
              <div className="mb-12 font-semibold" id="lender_signature">
                In the presence of:
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <div className="">SIGNED by</div>
                  <div className="">{contractQuery.data?.lender?.basicInformation?.username}</div>
                  <div className=""></div>
                  <div className=""></div>
                </div>
                <div className="">
                  <div className="">
                    {contractQuery.data?.lender_signature?.confirmed_by_lender && (
                      <img
                        src={
                          UPLOADS_URL +
                          '/signatures/' +
                          contractQuery.data?.lender_signature?.location
                        }
                        className="h-16"
                        alt="Signature"
                      />
                    )}
                    {!contractQuery.data?.lender_signature?.confirmed_by_lender &&
                      user.role === 'LENDER' && (
                        <SubmitLenderSignature
                          location={contractQuery.data?.lender_signature?.location}
                          id={id}
                        />
                      )}
                  </div>
                  <div className="">WITNESS</div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="border border-gray-500 p-4 mb-4">
                <div className="font-bold">ENABLING FINANCE LIMITED</div>
                <div className="">
                  SUBJECT: {contractQuery.data?.product?.product_title} SCHEDULE
                </div>
                <div className="">
                  CLIENT: {contractQuery.data?.borrower?.basicInformation?.firstName}
                </div>
                <div className="">
                  INTEREST: {contractQuery.data?.loan?.interestRate}% PER MONTH REDUCING BALANCE
                </div>
                <div className="">DATE: {contractQuery.data?.contract_date}</div>
              </div>
              {contractQuery.data?.loan?.payments && (
                <>
                  <div className="font-bold mb-3">Repayment Schedule</div>
                  <div className="overflow-x-auto">
                    <table className="table-auto border-collapse border border-gray-400 w-full">
                      <thead>
                        <tr>
                          <th className="px-4 py-2 border border-gray-400">Installment Number</th>
                          <th className="px-4 py-2 border border-gray-400">Date Expected</th>
                          <th className="px-4 py-2 border border-gray-400">Installment Amount</th>
                          <th className="px-4 py-2 border border-gray-400">Principal Due</th>
                          <th className="px-4 py-2 border border-gray-400">Interest Due</th>
                          <th className="px-4 py-2 border border-gray-400">Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contractQuery.data?.loan?.payments.map((payment, index) => (
                          <tr key={payment._id}>
                            <td className="px-4 py-2 border border-gray-400">
                              {index === 0 ? null : payment.installmentNumber}
                            </td>
                            <td className="px-4 py-2 border border-gray-400">
                              {index === 0
                                ? null
                                : new Date(payment.dateExpected).toLocaleDateString()}
                            </td>
                            <td className="px-4 py-2 border border-gray-400">
                              {index === 0
                                ? null
                                : payment.installmentAmount
                                  ? payment.installmentAmount?.toLocaleString()
                                  : '-'}
                            </td>
                            <td className="px-4 py-2 border border-gray-400">
                              {payment.principalDue?.toLocaleString()}
                            </td>
                            <td className="px-4 py-2 border border-gray-400">
                              {payment.interestDue?.toLocaleString()}
                            </td>
                            <td className="px-4 py-2 border border-gray-400">
                              {payment.balance?.toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="border-4 border-gray-800 p-4 md:p-12 mb-4">
            <div className="mx-auto flex flex-col items-center space-y-1 mb-4 text-sm md:text-md">
              <div className="font-bold">THE REPUBLIC OF UGANDA</div>
              <div className="font-bold">IN THE MATTER OF</div>

              <div className="font-bold underline">MEMORANDUM OF DEPOSIT</div>

              <div className="">
                <span className="font-bold">THIS MEMORANDUM </span> made this day of{' '}
                <span
                  className={`font-bold ${
                    contractQuery.data?.contract_date === 'Pending' && `text-red-500`
                  }`}
                >
                  {formatDateToISO8601(contractQuery.data?.contract_date)}
                </span>
              </div>
              <div className="font-bold">BETWEEN</div>
              <div className="">
                <span className="">
                  <div className="font-bold">
                    <div>{contractQuery.data?.borrower?.basicInformation?.firstName} of </div>
                    <div className="">
                      {contractQuery.data?.borrower?.addressInformation?.permanentAddress ? (
                        <>{contractQuery.data?.borrower?.addressInformation?.permanentAddress}</>
                      ) : (
                        <>
                          <UpdateAddress />
                        </>
                      )}
                    </div>
                  </div>
                  , (hereinafter referred to as “<span className="font-bold">the Borrower</span>”,
                  which expression shall where the context so admits include his legal
                  representatives, assigns and/or successors in title, of the first part;
                </span>
                <div className="mb-6 font-bold text-center">AND</div>
                <span className="font-bold">
                  ENABLING FINANCE LTD operating through her ‘Finablr’ app,{' '}
                </span>{' '}
                of <span className="font-bold">P.O. Box 12284, Kampala Tel: 0706400057 </span>{' '}
                (hereinafter referred to as <span className="font-bold">“the Lender”</span> which
                expression shall where the context so admits includes its successors in title, legal
                representatives and assigns) of the one part;
              </div>
              <div className="my-8">WITNESSETH AS FOLLOWS:</div>
              <div className="">
                WHEREAS at the request and for the benefit of the Borrower, the Lender has agreed to
                provide a Loan facility of Ug. Shs.{' '}
                {contractQuery.data?.loanAmount?.toLocaleString()} (
                {contractQuery.data && convertToWords(contractQuery.data?.loanAmount)} Only) to be
                paid in {contractQuery.data?.loan?.term} (
                {contractQuery.data && convertToWords(contractQuery.data?.loan?.term)}) monthly
                instalments as specified in the attached repayment schedule and secured by the
                Property specifically described in the Schedule hereto. The Borrower and the Lender
                have to this end entered into a Loan Agreement dated the{' '}
                <span className="font-bold">
                  {formatDateToISO8601(contractQuery.data?.contract_date)}
                </span>{' '}
                and it is agreed that the said Loan Agreement be deemed integral and construed as
                one with this Memorandum.
              </div>
              <div className="my-8">NOW THEREFORE IT IS HEREBY AGREED AS FOLLOWS:</div>
              <div className="">
                In consideration of and as security for payment of the Loan amount with interest as
                provided in the Loan Agreement, the Borrower deposits with the Lender the collateral
                below;
                <br />
                {contractQuery.data?.loan?.collateral &&
                  contractQuery.data?.loan?.collateral?.map((collateral, index) => (
                    <ContractCollateral collateral={collateral} index={index} />
                  ))}{' '}
                <br />
                For the Property specifically described in the Schedule (hereinafter referred as{' '}
                <span className="font-bold">‘the Security’</span> ).
              </div>
              <div className="">
                In the event that the Borrower defaults in the payment of any installment, the whole
                amount of the Loan advanced will be come due and payable with or without a formal
                written demand.
              </div>
              <div className="">
                For the avoidance of any doubt, in the event that the Borrower defaults in the
                payment of any installments of the Loan amount as and when they fall due or commits
                any other or further breach of the terms of and under the Loan Agreement the Lender
                shall have the undisputed right to dispose of the Security in realization thereof.
              </div>
              <div className="my-8">
                IN WITNESS WHEREOF the parties have hereunto set respective hands on the date month
                and year first above written
              </div>
              <div className="my-8">SIGNED and delivered by</div>
              <div className="mb-4">
                <div className="grid grid-cols-2 gap-4 mb-12 items-center">
                  <div className="">
                    <div className="">SIGNED by</div>
                    <div className="">
                      {contractQuery.data?.borrower_signature?.confirmed_by_borrower && (
                        <img
                          src={
                            UPLOADS_URL +
                            '/signatures/' +
                            contractQuery.data?.borrower_signature?.location
                          }
                          className="h-16"
                          alt="Signature"
                        />
                      )}
                    </div>
                    <div className="">
                      {' '}
                      {contractQuery.data?.borrower?.basicInformation?.firstName}
                    </div>
                    <div className="">BORROWER</div>
                  </div>
                </div>

                <div className="my-4">In the presence of;</div>

                <div className="grid grid-cols-2 gap-4 mb-12 items-center">
                  <div className="">
                    <div className="">YYEYO LUKE</div>
                    <div className="">On behalf of</div>
                    <div className="">LENDER</div>
                    <div className="">Enabling Finance Limited</div>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="">
                    <div className="">
                      {contractQuery.data?.lender_signature?.confirmed_by_lender && (
                        <img
                          src={
                            UPLOADS_URL +
                            '/signatures/' +
                            contractQuery.data?.lender_signature?.location
                          }
                          className="h-16"
                          alt="Signature"
                        />
                      )}
                    </div>
                    <div className="">
                      {' '}
                      {contractQuery.data?.lender?.basicInformation?.firstName}
                    </div>
                    <div className="">WITNESS</div>
                    <div className=""></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <DueDiligenceComponentFiles dueDiligence={contractQuery.data?.due_diligence} /> */}
        </div>
      </div>

      {/* Audit Log */}
      <AuditLog comments={contractQuery.data?.loan?.comments} />
    </ContentLayout>
  );
};
