import { Button } from 'components/Elements';
import { Form, InputField } from 'components/Form';
import { User } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { useForgotPassword } from '../api/forgotPassword';
import { useState } from 'react';

const schema = z.object({
  email: z.string().min(1, 'Required'),
});

export const ForgotPasswordForm = () => {
  const forgotPasswordMutation = useForgotPassword();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  return (
    <div>
      {forgotPasswordMutation.isSuccess ? (
        <div className="mt-2 flex items-center justify-center">
          <div className="text-green-600">Password reset instructions sent successfully!</div>
        </div>
      ) : (
        <Form
          onSubmit={async () => {
            console.log(email);
            await forgotPasswordMutation.mutateAsync({
              email: {
                email: email,
              },
            });
          }}
          // schema={schema}
        >
          {({ register, formState }) => (
            <>
              <InputField
                type="email"
                icon={<User />}
                label="Email Address"
                placeholder="Provider your email address"
                error={formState.errors['email']}
                registration={register('email')}
                onChange={(e) => setEmail(e.target.value)}
              />

              <div>
                <Button
                  isLoading={forgotPasswordMutation.isLoading}
                  type="submit"
                  className="w-full text-gray-900"
                >
                  Forgot Password
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          <Link to="../login" className="font-medium">
            Go to Login
          </Link>
        </div>
      </div>
    </div>
  );
};
